<template>
    <span class="text-end">
        You decide
        <span v-if="isMinPrice && !isMaxPrice">({{ formatMinPrice }} minimum)</span>
        <span v-if="!isMinPrice && isMaxPrice">({{ formatMaxPrice }} maximum)</span>
        <span v-if="isMinPrice && isMaxPrice">({{ formatMinPrice }} to {{ formatMaxPrice }})</span>
    </span>
</template>

<script>
// import { toNumber } from '@/sdk/currency';
import { fromCurrencyAmountCSU } from '@libertyio/currency-util-js';

export default {
    props: ['price'],
    computed: {
        isMinPrice() {
            return Number.isInteger(this.price.tiers[0].min_price_csu) && this.price.tiers[0].min_price_csu > 0;
        },
        isMaxPrice() {
            return Number.isInteger(this.price.tiers[0].max_price_csu) && this.price.tiers[0].max_price_csu > 0;
        },
        formatMinPrice() {
            // return fromCurrencyAmountCSU(this.price.currency, this.price.tiers[0].min_price_csu).toStringWithCurrencySymbol();
            const price = fromCurrencyAmountCSU(this.price.currency, this.price.tiers[0].min_price_csu).toNumber();
            const display = new Intl.NumberFormat('en-US', {
                currency: this.price.currency,
                style: 'currency',
            }).format(price);
            return display;
        },
        formatMaxPrice() {
            // return fromCurrencyAmountCSU(this.price.currency, this.price.tiers[0].max_price_csu).toStringWithCurrencySymbol();
            const price = fromCurrencyAmountCSU(this.price.currency, this.price.tiers[0].max_price_csu).toNumber();
            const display = new Intl.NumberFormat('en-US', {
                currency: this.price.currency,
                style: 'currency',
            }).format(price);
            return display;
        },
    },
};
</script>
