<template>
    <v-simple-table>
        <template #default>
            <thead>
                <tr>
                    <th>Quantity (units of {{ unitLabel }})</th>
                    <th>Total</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <!-- <v-text-field v-model="quantity" outlined dense :color="primaryColor" label="Quantity" hint="Check price for this quantity"/> -->
                        <v-text-field v-model="quantity" dense :color="primaryColor" :error-messages="quantityInvalidErrorMessage" @keyup.enter.prevent="calculate">
                            <template #append>
                                <v-btn icon :color="primaryColor" x-small @click="calculate">
                                    <font-awesome-icon :icon="['fas', 'calculator']" fixed-width style="font-size: 16px;"/>
                                </v-btn>
                            </template>
                        </v-text-field>
                    </td>
                    <td>
                        {{ formatAmount(total) }}
                    </td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
// import { toNumber } from '@/sdk/currency';
import { isValidUnsignedDecimalString, fromCurrencyAmountCSU, CurrencyAmount } from '@libertyio/currency-util-js';

export default {
    props: ['price'],
    data: () => ({
        quantity: null,
        total: null,
        quantityInvalidError: false,
        tierIndex: null,
    }),
    computed: {
        ...mapState({
            brandprofile: (state) => state.brandprofile,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
            accentColor: 'accentColor',
        }),
        more() {
            return this.price.tiers.length > 1;
        },
        unitLabel() {
            return this.price.unit_label ?? 'unit';
        },
        flat() {
            return Number.isInteger(this.price.tiers[0].flat_price_csu) && this.price.tiers[0].flat_price_csu > 0;
        },
        unit() {
            return isValidUnsignedDecimalString(this.price.tiers[0].unit_price_cds) && Number.parseFloat(this.price.tiers[0].unit_price_cds) > 0;
        },
        flatAnywhere() {
            return this.price.tiers.filter((item) => Number.isInteger(item.flat_price_csu) && item.flat_price_csu > 0).length > 0;
        },
        unitAnywhere() {
            return this.price.tiers.filter((item) => isValidUnsignedDecimalString(item.unit_price_cds) && Number.parseFloat(item.unit_price_cds) > 0).length > 0;
        },
        both() {
            return this.flat && this.unit;
        },
        quantityInvalidErrorMessage() {
            return this.quantityInvalidError ? 'Enter a valid quantity' : null;
        },
    },
    watch: {
        tierIndex(newValue) {
            this.$emit('highlight', { tierIndex: newValue });
        },
    },
    methods: {
        calculate() {
            this.tierIndex = null;
            let quantity;
            if (Number.isInteger(this.quantity)) {
                quantity = this.quantity;
            } else if (typeof this.quantity === 'string') {
                quantity = Number.parseInt(this.quantity, 10);
            } else {
                this.quantityInvalidError = true;
                this.total = null;
                return;
            }
            if (!Number.isInteger(quantity) || quantity < 0) {
                this.quantityInvalidError = true;
                this.total = null;
                return;
            }
            let tierIndex = null;
            for (let i = 0; i < this.price.tiers.length; i += 1) {
                const tier = this.price.tiers[i];
                if (Number.isInteger(tier.max) && quantity <= tier.max) {
                    tierIndex = i;
                    break;
                }
            }
            // check if last tier is unlimited
            const lastTierIndex = this.price.tiers.length - 1;
            if (tierIndex === null && !Number.isInteger(this.price.tiers[lastTierIndex].max)) {
                tierIndex = lastTierIndex;
            }
            if (tierIndex === null) {
                this.quantityInvalidError = true;
                this.total = null;
                return;
            }
            this.tierIndex = tierIndex;
            const tier = this.price.tiers[tierIndex];
            let subtotal = 0; // CSU
            if (Number.isInteger(tier.flat_price_csu) && tier.flat_price_csu > 0) {
                subtotal += tier.flat_price_csu;
                console.log(`subtotal after flat price: ${subtotal}`);
            }
            if (isValidUnsignedDecimalString(tier.unit_price_cds) && Number.parseFloat(tier.unit_price_cds) > 0) {
                subtotal += new CurrencyAmount(this.price.currency, Number.parseFloat(tier.unit_price_cds) * quantity).toCSU();
                console.log(`subtotal after unit price: ${subtotal}`);
            }
            this.total = subtotal; // CSU
            this.quantityInvalidError = false;
        },
        formatAmount(amount) {
            // return fromCurrencyAmountCSU(this.price.currency, amount).toStringWithCurrencySymbol();
            const price = fromCurrencyAmountCSU(this.price.currency, amount ?? 0).toNumber();
            const display = new Intl.NumberFormat('en-US', {
                currency: this.price.currency,
                style: 'currency',
            }).format(price);
            return display;
        },
        formatFlatPrice(tier) {
            // return fromCurrencyAmountCSU(this.price.currency, tier.flat_price_csu).toStringWithCurrencySymbol();
            const price = fromCurrencyAmountCSU(this.price.currency, tier.flat_price_csu ?? 0).toNumber();
            const display = new Intl.NumberFormat('en-US', {
                currency: this.price.currency,
                style: 'currency',
            }).format(price);
            return display;
        },
        formatUnitPrice(tier) {
            const price = Number.parseFloat(tier.unit_price_cds ?? '0');
            const display = new Intl.NumberFormat('en-US', {
                currency: this.price.currency,
                style: 'currency',
            }).format(price);
            return display;
        },
    },
};
</script>
