<template>
    <v-card flat>
    <template v-if="!isAuthenticatedReady || !cart">
        <v-progress-circular indeterminate size="16" width="2" :color="primaryColor" />
    </template>
    <template v-if="isAuthenticatedReady && !isAuthenticated && cart">
        <template v-if="cart.require.require_signup">
            <!-- TODO "product A and # others require sign up" -->
            <!-- <p class="text-start">At least one item in your cart requires you to sign in to your account or sign up for a new account.</p> -->
            <!-- TODO: explain why we need this -- because of recurring billing, subscription, or membership -->
            <template v-if="registrationModeConnect">
                <!-- TODO: add current URL as return URL so user can return here after creating an accoutn.... -->
                <!-- TODO: you can only sign up at the other website... then you have to come back to complete your purchase -->
                <p class="text-start">You must <a :href="registrationExternalURL">create an account</a> for this order.</p>
            </template>
            <template v-if="registrationModeOpen">
                <!-- <p>A new account will be created for this order.</p> -->
                <v-text-field v-model="name" :error-messages="inputError.name" dense solo/>
                <v-text-field v-model="email" :error-messages="inputError.email" dense solo/>
            </template>
            <template v-if="registrationModeRestricted">
                <!-- TODO: we need to check if user has an invitation, maybe there's a code they can enter? or they got here with a link and we have an approval in the interaction? -->
                <p class="text-start">New accounts are currently restricted. Please contact customer support.</p>
            </template>
            <template v-if="registrationModeClosed">
                <p class="text-start">You must <a :href="registrationExternalURL">create an account</a> for this order.</p>
            </template>
            <template v-if="registrationModeModerated">
                <!-- TODO: we need a link where user can request an account; just redirect them there, since they can't finish checkout without an account -->
                <p class="text-start">New account requests are currently being moderated. You must request an account before you can complete this checkout. Please contact customer support.</p>
            </template>
        </template>
        <!-- <template v-else-if="authenticationModeDelegate">
            < ! - - Sign up isn't required, so we offer a checkbox for optional signup; TODO: only offer this if organization settings allow signup, because some organizations might want to have ONLY guest accounts ; note that this might a different setting than authenticationMode, and we need to figure out what combination of settings we can allow here - - >
            < ! - - TODO: alternative text?
            "Save my order information to access later"
            "Create a new account (optional)"
            - - >
            <v-checkbox v-model="signupCheckbox" label="Create an account (optional)" class="hide-messages mt-0 pt-0" :color="primaryColor" dense/>
            <p class="text-caption text-start">
                This allows you to sign in later and review past orders, easily contact customer support, and more.
                < ! - - Without an account, you will need to save your receipt so you can provide your customer ID and order ID if you need a refund or support for a specific purchase. General customer support would still be available without a receipt. - - >
                < ! - - After checkout, we will send you an email with a link to set up your account. - - >
            </p>
        </template> -->
        <p class="text-start">
            Already have an account?
            <TextButton :color="primaryColor" @click="login" underline>Sign in</TextButton>
        </p>
        <!-- <p class="text-start">
            <TextButton :color="primaryColor" @click="createGuestAccount" underline>Continue as a guest</TextButton>
        </p> -->
    </template>
    <template v-if="isAuthenticated && cart && !account && Array.isArray(accountList) && accountList.length === 0">
        <template v-if="registrationModeConnect">
            <p class="text-start">You must <a :href="registrationExternalURL">create an account</a> for this order.</p>
        </template>
        <template v-if="registrationModeOpen">
            <p class="text-start">A new account will be created for this order.</p>
        </template>
        <template v-if="registrationModeRestricted">
            <!-- TODO: we need to check if user has an invitation, maybe there's a code they can enter? or they got here with a link and we have an approval in the interaction? -->
            <p class="text-start">New accounts are currently restricted. Please contact customer support.</p>
        </template>
        <template v-if="registrationModeClosed">
            <p class="text-start">You must <a :href="registrationExternalURL">create an account</a> for this order.</p>
        </template>
        <template v-if="registrationModeModerated">
            <!-- TODO: we need a link where user can request an account; just redirect them there, since they can't finish checkout without an account -->
            <p class="text-start">New account requests are currently being moderated. You must request an account before you can complete this checkout. Please contact customer support.</p>
        </template>
    </template>
    <template v-if="isAuthenticated && cart && !account && Array.isArray(accountList) && accountList.length > 0">
        <p>
            <TextButton :color="primaryColor" @click="switchAccountDialog = true" underline>Select an account for this order</TextButton>
        </p>
    </template>
    <template v-if="isAuthenticated && cart && account">
        <template v-if="compact">
            <p class="text-start">
                Account name:
                <strong>{{ account.name }}</strong>
                (<TextButton :color="primaryColor" @click="switchAccountDialog = true" underline>Switch</TextButton>)
            </p>
        </template>
        <template v-else>
            <p class="text-start">
                The order will be placed under the following account:
            </p>
            <p class="text-start mb-0 text-body-1">
                <strong>{{ account.name }}</strong>
                <!-- <v-btn :style="primaryIconStyle" class="no-print" @click="switchAccountDialog = true" icon>
                        <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width/>
                </v-btn> -->
            </p>
            <p class="text-start text-caption">
                <!-- <v-btn text x-small :color="primaryColor" @click="switchAccountDialog = true">Switch account</v-btn> -->
                <!-- <span style="text-decoration: underline; cursor: pointer;" :style="primaryColor" @click="switchAccountDialog = true">Use a different account</span> -->
                <TextButton :color="primaryColor" @click="switchAccountDialog = true" underline>Use a different account</TextButton>
            </p>
            <p class="text-start mt-2">
                <v-btn :style="primaryButtonStyle" @click="selectAccount(account.id)">Continue</v-btn>
            </p>
        </template>
    </template>
    <!-- NOTE: only show this dialog if there is at least one account already; if no accounts we will create one automatically so no reason to show this dialog -->
    <v-dialog v-model="switchAccountDialog" max-width="600">
        <v-card tile elevation="4" class="pa-0" max-width="600">
            <v-toolbar short flat color="white">
                <v-toolbar-title :color="primaryColor">Select an account for this order</v-toolbar-title>
                <!-- TODO: a "+" button for creating a new account;  -->
            </v-toolbar>
            <v-card-text class="px-5">
                <v-list dense>
                <v-list-item v-for="item in accountList" v-bind:key="item.id" class="my-2" @click="selectAccount(item.id)">
                    <v-list-item-content class="text-start">
                        {{ item.name }}
                    </v-list-item-content>
                </v-list-item>
                <!-- For registration mode 'connect', we don't offer to create a new account here, because it has to be done at the client application; TODO: for registration mode conect, show a link to the client application url to manage accounts, then user can return here after they create it -->
                <v-list-item class="my-2" @click="createAccountForm = true" v-if="!registrationModeConnect && !createAccountForm">
                    <!-- TODO: check organization settings, if customers are allowed to have multiple accounts; if not, hide this option -->
                    <TextButton :color="primaryColor" underline>Create a new account</TextButton>
                </v-list-item>
                </v-list>
                <v-form @submit.prevent="createAccount" v-if="createAccountForm" @keyup.enter.prevent="createAccount">

                    <v-radio-group v-model="newAccountType" column dense> <!-- @change="onChangeNewAccountType" -->
                        <v-radio label="Individual" :value="ACCOUNT_TYPE_INDIVIDUAL"></v-radio>
                        <v-radio label="Team" :value="ACCOUNT_TYPE_TEAM"></v-radio>
                        <v-radio label="Enterprise" :value="ACCOUNT_TYPE_ENTERPRISE"></v-radio>
                    </v-radio-group>
                    <p v-if="newAccountType === ACCOUNT_TYPE_INDIVIDUAL">
                        You will be the only user on this account. This option is suitable for hobbyists, professionals, and solopreneurs.
                    </p>
                    <p v-if="newAccountType === ACCOUNT_TYPE_TEAM">
                        You'll be the account owner (you can change this later). You will be able to restrict certain account actions so they require your permission. This option is suitable for small business and other situations where the account owner needs to invite at least one other person to be a DNS administrator.
                    </p>
                    <p v-if="newAccountType === ACCOUNT_TYPE_ENTERPRISE">
                        You'll be the first account administrator. You can then invite others to become authorized technical, billing, or customer support contacts. You will have the ability to manage permissions in detail.
                    </p>
                    <v-text-field v-model="newAccountName" outlined dense :label="newAccountNameLabel" :color="primaryColor" ref="newAccountNameInput" hint="This will be the account name. You can change it later." v-if="newAccountType !== ACCOUNT_TYPE_INDIVIDUAL"></v-text-field>

                    <v-btn elevation="4" :style="primaryButtonStyle" @click="createAccount" :disabled="!isCreateAccountFormComplete">
                        <span>Create</span>
                    </v-btn>
                    <v-btn text color="grey" @click="createAccountForm = false">
                        <span>Cancel</span>
                    </v-btn>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
    </v-card>
</template>

<style>
/* regular input height is 56px; dense input height is 40px */
/* font awesome icon width is 16px, while append/prepend-inner width is 20px */
.v-input .v-input__prepend-inner {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
.v-input .v-input__prepend-outer {
    margin-left: 2px !important; /* (20px placeholder width - 16px icon width) / 2 */
    padding-left: 2px !important;
    margin-top: 12px !important; /* (40px input height - 16px icon height) / 2 */
    margin-bottom: 12px !important;
    padding: 0px;
}
/* remove messages area from inputs */
.v-input.v-input--checkbox.hide-messages .v-messages {
    display: none;
}
/* align table headings and content to top */
.finalize-order-form th {
    vertical-align: top;
    padding-top: 6px !important;
    padding-bottom: 4px !important;
}
.finalize-order-form td {
    vertical-align: top;
    padding-top: 6px !important;
    padding-bottom: 4px !important;
}
/* remove hover background change from data table */
.v-data-table tr:hover {
    background-color: #ffffff !important;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
import {
    ACCOUNT_TYPE_INDIVIDUAL,
    ACCOUNT_TYPE_TEAM,
    ACCOUNT_TYPE_ENTERPRISE,
    SETTING_AUTHENTICATION_MODE,
    SETTING_REGISTRATION_MODE,
    SETTING_REGISTRATION_EXTERNAL_URL,
    AUTHENTICATION_MODE_DELEGATE,
    REGISTRATION_MODE_CONNECT,
    REGISTRATION_MODE_OPEN,
    REGISTRATION_MODE_RESTRICTED,
    REGISTRATION_MODE_MODERATED,
    REGISTRATION_MODE_CLOSED,
} from '@/sdk/constants';
import TextButton from '@/components/TextButton.vue';

export default {
    props: ['compact'],
    components: {
        TextButton,
    },
    data: () => ({
        signupCheckbox: false,
        switchAccountDialog: false,
        createAccountForm: false,
        newAccountName: null,
        newAccountType: ACCOUNT_TYPE_INDIVIDUAL, // TODO: check organization settings if there's a default account type (some companies sell mostly to people, or professionals, or corporations, or government, etc.)
        formSubmitTimestamp: null,
        createAccountError: false,
        createAccountRedirect: null,
        authenticationMode: null, // possible values: 'connect', 'delegate', 'sso'; indicates where user can sign in
        registrationMode: null, // possible values: 'connect' (must go to organization website to create new account), 'open' (can create a new account), 'restricted' (by invitation only), 'moderated' (register here, wait for approval), 'closed' (cannot create a new account)
        registrationExternalURL: null, // only for registration mode 'connect'
    }),
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isAuthenticatedReady,
            brandprofile: (state) => state.brandprofile,
            session: (state) => state.session,
            user: (state) => state.user,
            cart: (state) => state.cart,
            focus: (state) => state.focus,
            account: (state) => state.account,
            accountList: (state) => state.accountList,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
            accentColor: 'accentColor',
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        authenticationModeDelegate() {
            return this.authenticationMode === AUTHENTICATION_MODE_DELEGATE;
        },
        registrationModeConnect() {
            return this.registrationMode === REGISTRATION_MODE_CONNECT;
        },
        registrationModeOpen() {
            return this.registrationMode === REGISTRATION_MODE_OPEN;
        },
        registrationModeRestricted() {
            return this.registrationMode === REGISTRATION_MODE_RESTRICTED;
        },
        registrationModeModerated() {
            return this.registrationMode === REGISTRATION_MODE_MODERATED;
        },
        registrationModeClosed() {
            return this.registrationMode === REGISTRATION_MODE_CLOSED;
        },
        isCreateAccountFormComplete() {
            return this.newAccountName && this.newAccountType;
        },
        ACCOUNT_TYPE_INDIVIDUAL() {
            return ACCOUNT_TYPE_INDIVIDUAL;
        },
        ACCOUNT_TYPE_TEAM() {
            return ACCOUNT_TYPE_TEAM;
        },
        ACCOUNT_TYPE_ENTERPRISE() {
            return ACCOUNT_TYPE_ENTERPRISE;
        },
        newAccountNameLabel() {
            if (this.newAccountType === ACCOUNT_TYPE_TEAM) {
                return 'Team, project, or business name';
            }
            if (this.newAccountType === ACCOUNT_TYPE_ENTERPRISE) {
                return 'Organization name';
            }
            return 'Account name';
        },
    },
    watch: {
        isAuthenticatedReady(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.init();
            }
        },
        brandprofile() {
            this.init();
        },
        focus() {
            this.init();
        },
        account(newValue) {
            // console.log(`CheckoutSelectAccount.vue watcher/account: ${JSON.stringify(newValue)} vs cart ${this.cart.account_id}`);
            if (newValue.id !== this.cart?.account_id) {
                this.editCartAccount(newValue.id);
            }
        },
        accountList(newValue) {
            if (!Array.isArray(newValue)) {
                console.log('CheckoutSelectAccount.vue: watch accountList: not an array');
                return;
            }
            if (newValue.length > 0 && !this.account) {
                console.log('CheckoutSelectAccount.vue: watch accountList: changed, auto-selecting account');
                this.selectAccount(newValue[0].id);
                // return;
            }
            // if (newValue.length === 0 && !this.account) {
            //     if (!this.newAccountName) {
            //         this.newAccountName = 'My Account';
            //     }
            //     if (!this.newAccountType) {
            //         this.newAccountType = ACCOUNT_TYPE_INDIVIDUAL;
            //     }
            //     this.createAccount();
            // }
        },
        cart() {
            console.log('CheckoutSelectAccount.vue: watch cart: changed');
            this.init();
        },
    },
    methods: {
        async editCartAccount(accountId) {
            // console.log(`CheckoutSelectAccount.vue calling editCartAccount with account id ${accountId}`);
            await this.$client.site(this.brandprofile).cart.edit({ op: 'set_account', account_id: accountId });
        },
        async selectAccount(accountId) {
            console.log(`CheckoutSelectAccount.vue: selectAccount: selecting account ${accountId}`);
            await this.$store.dispatch('loadAccount', { accountId }); // this should trigger the account watcher in this .vue file that calls editCartAccount
            console.log(`selectAccount: account is now ${JSON.stringify(this.account)}`);
            if (this.account) {
                this.$nav.set('account_id', this.account.id);
                this.switchAccountDialog = false;
                this.$emit('selected', accountId);
            }
        },
        async createAccount() {
            try {
                if (Number.isInteger(this.formSubmitTimestamp) && this.formSubmitTimestamp + 500 > Date.now()) {
                    return;
                }
                this.formSubmitTimestamp = Date.now();
                this.$store.commit('loading', { createAccount: true });
                const result = await this.$client.site(this.brandprofile).user(this.user.id).account.create({
                    name: this.newAccountName,
                    type: this.newAccountType,
                });
                if (result.isCreated && result.id && result.linkAccountUser) {
                    this.accountList.push(result.linkAccountUser);
                    this.selectAccount(result.id);
                } else if (!result.isCreated && result.redirect) {
                    this.createAccountError = true;
                    this.createAccountRedirect = result.redirect;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create a new account' });
                }
            } catch (err) {
                console.error('create account failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create a new account' });
                // TODO: switch back to select-account mode in the dialog
            } finally {
                this.$store.commit('loading', { createAccount: false });
            }
        },
        /**
         * `accountId` is optional; if provided and is present in the account list, will be automatically selected; otherwise if there's an account already set in the cart it will be selected; otherwise first account in list will be automatically selected
         */
        async loadAccountList({ accountId } = {}) {
            console.log('CheckoutSelectAccount.vue: loadAccountList');
            try {
                if (!this.isAuthenticated || !this.user) {
                    console.log('CheckoutSelectAccount.vue: loadAccountList cancelled because not authenticated');
                    // this.accountList = null;
                    // this.account = null;
                    return;
                }
                this.$store.commit('loading', { loadAccountList: true });
                // console.log(`CheckoutSelectAccount.vue: linkAccountUser.search ${this.user.id}`);
                await this.$store.dispatch('loadAccountList');
                // this.accountList = result.list;
                console.log(`CheckoutSelectAccount.vue: user has ${this.accountList.length} accounts`);
                if (this.accountList.length > 0) {
                    let account;
                    // check if an account id was specified by the caller
                    if (accountId) {
                        // console.log(`loadAccountList with specified account id: ${accountId}`);
                        account = this.accountList.find((item) => item.id === accountId);
                    }
                    // try the most recently selected account first
                    if (!account && this.cart?.account_id) {
                        // console.log(`loadAccountList with cart account id: ${this.cart.accountId}`);
                        account = this.accountList.find((item) => item.id === this.cart.account_id);
                    }
                    // use first account in the list as a default
                    if (!account) {
                        // console.log(`loadAccountList with first account id: ${result.list[0].account_id}`);
                        account = this.accountList[0];
                    }
                    // this.account = account;
                    this.selectAccount(account.id);
                }
            } catch (err) {
                console.error('CheckoutSelectAccount.vue: failed to load account list', err);
                if (err.response?.status) {
                    console.error(`response status: ${err.response.status}`);
                    // TODO: 300 error codes? server shouldn't be redirecting us...
                    if (err.response.status === 403) {
                        // this.resetErrors();
                        this.interactionId = null; // or else user will immediately get same forbidden error again; to start over we need to clear the interaction id
                        this.forbiddenError = true;
                        this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 400 && err.response.status < 500) {
                        this.requestError = true;
                        this.requestErrorTimeout = setTimeout(() => { this.requestError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    } else {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                }
            } finally {
                this.$store.commit('loading', { loadAccountList: false });
            }
        },
        async loadSetting(name) {
            const progressLabel = `loadSetting__${name}`;
            try {
                this.$store.commit('loading', { [progressLabel]: true });
                const result = await this.$client.site(this.brandprofile).setting.get({ name });
                if (result?.content) {
                    return result.content;
                }
                return null;
            } catch (err) {
                console.error('loadSetting failed', err);
                return null;
            } finally {
                this.$store.commit('loading', { [progressLabel]: false });
            }
        },
        async loadAuthenticationModeSetting() {
            this.authenticationMode = await this.loadSetting(SETTING_AUTHENTICATION_MODE);
        },
        async loadRegistrationModeSetting() {
            this.registrationMode = await this.loadSetting(SETTING_REGISTRATION_MODE);
            if (this.registrationMode === REGISTRATION_MODE_CONNECT) {
                const registrationExternalURL = await this.loadSetting(SETTING_REGISTRATION_EXTERNAL_URL);
                const w = new URL(registrationExternalURL);
                w.searchParams.set('return_url', window.location.href);
                this.registrationExternalURL = w.toString();
            }
        },
        async init() {
            if (!this.cart) {
                console.log('CheckoutSelectAccount.vue: loading cart');
                this.$store.dispatch('loadCart');
            }
            if (this.isAuthenticatedReady) {
                const accountId = this.$route.query.accountId ?? this.account?.id;
                this.loadAccountList({ accountId });
            }
            this.loadAuthenticationModeSetting();
            this.loadRegistrationModeSetting();
        },
        login() {
            this.$nav.push({ name: 'brand-login', query: { next: this.$router.currentRoute.fullPath } });
        },
    },
    mounted() {
        if (this.brandprofile) {
            this.init();
        }
    },
};
</script>
