<template>
    <div style="text-align: end;">
        <span>{{ formatPrice }} per {{ unitLabel }}</span>
        <span class="ml-1">
            <v-btn icon :color="accentColor" x-small @click="calculator = true" v-if="!calculator">
                <font-awesome-icon :icon="['fas', 'calculator']" fixed-width style="font-size: 16px;"/>
            </v-btn>
            <v-btn icon :color="accentColor" x-small @click="calculator = false" v-if="calculator">
                <font-awesome-icon :icon="['fas', 'calculator']" fixed-width style="font-size: 16px;"/>
            </v-btn>
        </span>
        <PriceDisplayUnitCalculator :price="price" v-show="calculator"/>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
// import { toNumber } from '@/sdk/currency';
// import { isValidUnsignedDecimalString, fromCurrencyAmountCSU } from '@libertyio/currency-util-js';
import PriceDisplayUnitCalculator from '@/components/PriceDisplayUnitCalculator.vue';

export default {
    components: {
        PriceDisplayUnitCalculator,
    },
    props: ['price'],
    data: () => ({
        calculator: false,
    }),
    computed: {
        ...mapState({
            brandprofile: (state) => state.brandprofile,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
            accentColor: 'accentColor',
        }),
        unitLabel() {
            return this.price.unit_label ?? 'unit';
        },
        formatPrice() {
            const tier = this.price.tiers[0];
            const price = Number.parseFloat(tier.unit_price_cds);
            const display = new Intl.NumberFormat('en-US', {
                currency: this.price.currency,
                style: 'currency',
            }).format(price);
            return display;
        },
    },
};
</script>
