<template>
    <component :is="displayComponent" :price="price" :disabled="disabled"></component>
</template>

<script>
// import { mapGetters } from 'vuex';
import { fromCurrencyAmountCDS } from '@libertyio/currency-util-js';
import PriceDisplayCustom from '@/components/PriceDisplayCustom.vue';
import PriceDisplayFlat from '@/components/PriceDisplayFlat.vue';
import PriceDisplayFlatFree from '@/components/PriceDisplayFlatFree.vue';
import PriceDisplayFree from '@/components/PriceDisplayFree.vue';
import PriceDisplayNone from '@/components/PriceDisplayNone.vue';
import PriceDisplayUnit from '@/components/PriceDisplayUnit.vue';
import PriceDisplayPackage from '@/components/PriceDisplayPackage.vue';
import PriceDisplayVolume from '@/components/PriceDisplayVolume.vue';
import PriceDisplayStacked from '@/components/PriceDisplayStacked.vue';

export default {
    props: {
        price: {
            type: Object,
            required: true,
        },
        hideFree: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        PriceDisplayCustom,
        PriceDisplayFlat,
        PriceDisplayFlatFree,
        PriceDisplayFree,
        PriceDisplayNone,
        PriceDisplayUnit,
        PriceDisplayPackage,
        PriceDisplayVolume,
        PriceDisplayStacked,
    },
    computed: {
    //     ...mapGetters({
    //         mainWebsiteURL: 'mainWebsiteURL',
    //     }),
    //     loginRoute() {
    //         if (this.nextRoute) {
    //             return { name: 'login', query: { next: this.nextRoute } };
    //         }
    //         return { name: 'login' };
    //     },

        isFreePrice() {
            return this.price.billing_method === 'free' && this.price.tiers.length === 0;
        },
        isFreeFlatPrice() {
            return this.price.billing_method === 'flat' && this.price.tiers.length === 1 && this.price.tiers[0].flat_price_csu === 0;
        },
        isFreeUnitPrice() {
            return this.price.billing_method === 'unit' && this.price.tiers.length === 1 && fromCurrencyAmountCDS(this.price.currency, this.price.tiers[0].unit_price_cds).toCSU() === 0;
        },
        isFree() {
            return this.isFreePrice || this.isFreeFlatPrice || this.isFreeUnitPrice;
        },
        displayComponent() {
            if (this.isFree) {
                return this.hideFree ? 'PriceDisplayNone' : 'PriceDisplayFlatFree';
            }
            switch (this.price.billing_method) {
            case 'custom':
                return 'PriceDisplayCustom';
            case 'flat':
                return 'PriceDisplayFlat';
            case 'free':
                return 'PriceDisplayFree';
            case 'unit':
                return 'PriceDisplayUnit';
            case 'package':
                return 'PriceDisplayPackage';
            case 'volume':
                return 'PriceDisplayVolume';
            case 'stacked':
                return 'PriceDisplayStacked';
            default:
                return null;
            }
        },
    },
};
</script>
