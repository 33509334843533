<template>
    <v-simple-table>
        <template #default>
            <thead>
                <tr>
                    <th>Quantity (units of {{ unitLabel }})</th>
                    <th>Total</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <!-- <v-text-field v-model="quantity" outlined dense :color="primaryColor" label="Quantity" hint="Check price for this quantity"/> -->
                        <v-text-field v-model="quantity" dense :color="primaryColor" :error-messages="quantityInvalidErrorMessage" @keyup.enter.prevent="calculate">
                            <template #append>
                                <v-btn icon :color="primaryColor" x-small @click="calculate">
                                    <font-awesome-icon :icon="['fas', 'calculator']" fixed-width style="font-size: 16px;"/>
                                </v-btn>
                            </template>
                        </v-text-field>
                    </td>
                    <td>
                        {{ formatAmount(total) }}
                    </td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { isValidUnsignedDecimalString, fromCurrencyAmountCSU } from '@libertyio/currency-util-js';
// import { toNumber } from '@/sdk/currency';

export default {
    props: ['price'],
    data: () => ({
        quantity: null,
        total: null,
        quantityInvalidError: false,
        tierIndexList: null,
    }),
    computed: {
        ...mapState({
            brandprofile: (state) => state.brandprofile,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
            accentColor: 'accentColor',
        }),
        more() {
            return this.price.tiers.length > 1;
        },
        unitLabel() {
            return this.price.unit_label ?? 'unit';
        },
        flat() {
            return Number.isInteger(this.price.tiers[0].flat_price_csu) && this.price.tiers[0].flat_price_csu > 0;
        },
        unit() {
            return isValidUnsignedDecimalString(this.price.tiers[0].unit_price);
        },
        flatAnywhere() {
            return this.price.tiers.filter((item) => Number.isInteger(item.flat_price_csu) && item.flat_price_csu > 0).length > 0;
        },
        unitAnywhere() {
            return this.price.tiers.filter((item) => isValidUnsignedDecimalString(item.unit_price)).length > 0;
        },
        both() {
            return this.flat && this.unit;
        },
        quantityInvalidErrorMessage() {
            return this.quantityInvalidError ? 'Enter a valid quantity' : null;
        },
    },
    watch: {
        tierIndexList(newValue) {
            this.$emit('highlight', { tierIndexList: newValue });
        },
    },
    methods: {
        // this starts at highest tier, fits as many items as possible, then goes to next lowest tier,
        // until no items are remaining from the user's specified quantity; this is a simple and easy
        // to understand algorithm but there are situations where it does not find the best price
        // returned subtotal value is in CSU
        calculateWithMinimizeQuantityOverage(quantity) {
            const tierIndexList = [];
            let remaining = quantity;
            let subtotal = 0; // CSU
            for (let i = this.price.tiers.length; i > 0; i -= 1) {
                const tier = this.price.tiers[i - 1];
                if (remaining >= tier.max) {
                    tierIndexList.unshift(i - 1);
                }
                while (remaining >= tier.max) {
                    remaining -= tier.max;
                    subtotal += Number.isInteger(tier.flat_price_csu) ? tier.flat_price_csu : 0;
                }
            }
            if (remaining > 0) {
                const tier = this.price.tiers[0];
                tierIndexList.unshift(0);
                remaining = 0;
                subtotal += Number.isInteger(tier.flat_price_csu) ? tier.flat_price_csu : 0;
            }
            return { subtotal, tierIndexList, alg: 'calculateWithMinimizeQuantityOverage' };
        },
        // there are situations where this doesn't find the best price
        // returned subtotal value is in CSU
        calculateWithMinimizePrice(quantity) {
            const tierIndexList = [];
            let remaining = quantity;
            let subtotal = 0; // CSU
            while (remaining > 0) {
                console.log(`loop start: remaining ${remaining}, subtotal ${subtotal}`);
                const compare = [];
                for (let i = 0; i < this.price.tiers.length; i += 1) {
                    const tier = this.price.tiers[i];
                    const count = Math.ceil(remaining / tier.max);
                    const flatPriceCSU = Number.isInteger(tier.flat_price_csu) ? tier.flat_price_csu : 0;
                    const amount = count * flatPriceCSU;
                    compare.push({ idx: i, quantity: count * tier.max, amount });
                }
                compare.sort((a, b) => a.amount - b.amount);
                const best = compare[0];
                console.log(`best: ${JSON.stringify(best)}`);
                remaining -= best.quantity;
                subtotal += best.amount; // CSU
                tierIndexList.unshift(best.idx);
            }
            return { subtotal, tierIndexList, alg: 'calculateWithMinimizePrice' };
        },
        calculate() {
            this.tierIndexList = [];
            let quantity;
            if (Number.isInteger(this.quantity)) {
                quantity = this.quantity;
            } else if (typeof this.quantity === 'string') {
                quantity = Number.parseInt(this.quantity, 10);
            } else {
                this.quantityInvalidError = true;
                this.total = null;
                return;
            }
            if (!Number.isInteger(quantity) || quantity < 0) {
                this.quantityInvalidError = true;
                this.total = null;
                return;
            }
            const result1 = this.calculateWithMinimizeQuantityOverage(quantity);
            const result2 = this.calculateWithMinimizePrice(quantity);
            const resultList = [result1, result2];
            resultList.sort((a, b) => a.subtotal - b.subtotal);
            resultList.forEach((result, idx) => {
                console.log(`result ${idx + 1}: ${JSON.stringify(result)}`);
            });
            this.total = resultList[0].subtotal; // CSU
            this.tierIndexList = resultList[0].tierIndexList;
            this.quantityInvalidError = false;
        },
        formatAmount(amount) {
            // return fromCurrencyAmountCSU(this.price.currency, amount).toStringWithCurrencySymbol();
            const price = fromCurrencyAmountCSU(this.price.currency, amount ?? 0).toNumber();
            const display = new Intl.NumberFormat('en-US', {
                currency: this.price.currency,
                style: 'currency',
            }).format(price);
            return display;
        },
        formatFlatPrice(tier) {
            // return fromCurrencyAmountCSU(this.price.currency, tier.flat_price_csu).toStringWithCurrencySymbol();
            const price = fromCurrencyAmountCSU(this.price.currency, tier.flat_price_csu ?? 0).toNumber();
            const display = new Intl.NumberFormat('en-US', {
                currency: this.price.currency,
                style: 'currency',
            }).format(price);
            return display;
        },
    },
};
</script>
