<template>
    <span class="text-caption">
        <span v-if="isFree">No charge</span> <!-- TODO: alternatively, "No payment required" -->
        <span v-if="!isFree && !price.recurring">One-time charge</span>
        <span v-if="!isFree && price.recurring">Recurring charge every {{ billingPeriod }}.</span>
        <!-- <span v-if="price.recurring && price.recurring_usage_type === 'metered'">Charges will vary with usage.</span> -->
        <span v-if="price.recurring && price.recurring_usage_type === 'metered' && price.billing_method !== 'flat'" class="ml-2">Charges will vary with usage.</span>
    </span>
</template>

<script>
// import { mapGetters } from 'vuex';
// import { toNumber } from '@/sdk/currency';
import { fromCurrencyAmountCDS } from '@libertyio/currency-util-js';

function plural(unit) {
    switch (unit) {
    case 'day':
        return 'days';
    case 'week':
        return 'weeks';
    case 'month':
        return 'months';
    case 'year':
        return 'years';
    default:
        return unit;
    }
}

export default {
    props: ['price'], // caller can pass `this.$router.currentRoute.fullPath` as the value of this prop to return to same page after login
    computed: {
        billingPeriod() {
            const count = this.price.recurring_interval_count;
            let unit;
            if (count === 1) {
                unit = this.price.recurring_interval_unit;
            } else {
                unit = plural(this.price.recurring_interval_unit);
            }
            return `${count} ${unit}`;
        },
        isFreePrice() {
            return this.price.billing_method === 'free' && this.price.tiers.length === 0;
        },
        isFreeFlatPrice() {
            return this.price.billing_method === 'flat' && this.price.tiers.length === 1 && this.price.tiers[0].flat_price_csu === 0;
        },
        isFreeUnitPrice() {
            return this.price.billing_method === 'unit' && this.price.tiers.length === 1 && fromCurrencyAmountCDS(this.price.currency, this.price.tiers[0].unit_price_cds).toCSU() === 0;
        },
        isFree() {
            return this.isFreePrice || this.isFreeFlatPrice || this.isFreeUnitPrice;
        },
    },
};
</script>
