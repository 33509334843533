<template>
    <v-simple-table>
        <template #default>
            <thead>
                <tr>
                    <!-- <th style="text-align: start;">From</th> -->
                    <th style="text-align: start;">Package Quantity</th>
                    <th style="text-align: end;">Package Price</th>
                    <!-- <th style="text-align: end;" v-if="flatAnywhere">Flat fee</th>
                    <th style="text-align: center;" v-if="flatAnywhere && unitAnywhere">+</th>
                    <th style="text-align: end;" v-if="unitAnywhere">Unit price</th> -->
                </tr>
            </thead>
            <tbody>
                <tr v-for="(tier, idx) in price.tiers" :key="idx" :class="{ highlight: highlightTierList.includes(idx) }">
                    <!-- <td style="text-align: start;">
                        <span v-if="idx === 0">1</span>
                        <span v-if="idx > 0">{{ price.tiers[idx-1].max + 1 }}</span>
                    </td> -->
                    <td style="text-align: start;">
                        <span>{{ tier.max }}</span>
                    </td>
                    <!-- <td style="text-align: end;" v-if="flatAnywhere">{{ formatFlatPrice(tier) }}</td>
                    <td style="text-align: center;" v-if="flatAnywhere && unitAnywhere"></td>
                    <td style="text-align: end;" v-if="unitAnywhere">{{ formatUnitPrice(tier) }}</td> -->
                    <td style="text-align: end;">
                        <span>{{ formatFlatPrice(tier) }}</span>
                    </td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<style scoped>
tr.highlight {
    background-color: #FFF59D; /* yellow lighten-3 */
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
// import { toNumber } from '@/sdk/currency';
import { fromCurrencyAmountCSU } from '@libertyio/currency-util-js';

export default {
    props: ['price', 'highlightTiers'],
    computed: {
        ...mapState({
            brandprofile: (state) => state.brandprofile,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
            accentColor: 'accentColor',
        }),
        more() {
            return this.price.tiers.length > 1;
        },
        // unitLabel() {
        //     return this.price.unit_label ?? 'unit';
        // },
        flat() {
            return Number.isInteger(this.price.tiers[0].flat_price_csu) && this.price.tiers[0].flat_price_csu > 0;
        },
        // unit() {
        //     return Number.isInteger(this.price.tiers[0].unit_price);
        // },
        // flatAnywhere() {
        //     return this.price.tiers.filter((item) => Number.isInteger(item.flat_price) && item.flat_price > 0).length > 0;
        // },
        // unitAnywhere() {
        //     return this.price.tiers.filter((item) => Number.isInteger(item.unit_price) && item.unit_price > 0).length > 0;
        // },
        // both() {
        //     return this.flat && this.unit;
        // },
        highlightTierList() {
            return Array.isArray(this.highlightTiers) ? this.highlightTiers : [];
        },
    },
    methods: {
        formatFlatPrice(tier) {
            // return fromCurrencyAmountCSU(this.price.currency, tier.flat_price_csu).toStringWithCurrencySymbol();
            const price = fromCurrencyAmountCSU(this.price.currency, tier.flat_price_csu ?? 0).toNumber();
            const display = new Intl.NumberFormat('en-US', {
                currency: this.price.currency,
                style: 'currency',
            }).format(price);
            return display;
        },
    },
};
</script>
