<template>
    <span class="text-end">
        {{ formatPrice }}
    </span>
</template>

<script>
// import { toNumber } from '@/sdk/currency';
import { fromCurrencyAmountCSU } from '@libertyio/currency-util-js';

export default {
    props: ['price'],
    computed: {
        formatPrice() {
            // return fromCurrencyAmountCSU(this.price.currency, this.price.tiers[0].flat_price_csu).toStringWithCurrencySymbol();
            const price = fromCurrencyAmountCSU(this.price.currency, this.price.tiers[0].flat_price_csu).toNumber();
            const display = new Intl.NumberFormat('en-US', {
                currency: this.price.currency,
                style: 'currency',
            }).format(price);
            return display;
        },
    },
};
</script>
