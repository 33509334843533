<template>
    <span class="text-end"></span>
</template>

<script>
// import { toNumber } from '@/sdk/currency';
// import { fromCurrencyAmountCSU } from '@libertyio/currency-util-js';

export default {
    props: ['price'],
};
</script>
