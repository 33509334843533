<template>
    <div style="text-align: end;">
        <span v-if="more">Starting at</span> <span v-if="flat">{{ formatFlatPrice(price.tiers[0]) }}</span><span v-if="both"> + </span><span v-if="unit">{{ formatUnitPrice(price.tiers[0]) }} per {{ unitLabel }}</span>
        <span v-if="more" class="ml-1">
            <v-btn icon :color="accentColor" x-small @click="calculator = true" v-if="!calculator">
                <font-awesome-icon :icon="['fas', 'calculator']" fixed-width style="font-size: 16px;"/>
            </v-btn>
            <v-btn icon :color="accentColor" x-small @click="calculator = false" v-if="calculator">
                <font-awesome-icon :icon="['fas', 'calculator']" fixed-width style="font-size: 16px;"/>
            </v-btn>
            <!-- <v-btn icon :color="accentColor" x-small @click="tiersTable = true" v-if="!tiersTable">
                <font-awesome-icon :icon="['fas', 'caret-right']" fixed-width style="font-size: 16px;"/>
            </v-btn>
            <v-btn icon :color="accentColor" x-small @click="tiersTable = false" v-if="tiersTable">
                <font-awesome-icon :icon="['fas', 'caret-down']" fixed-width style="font-size: 16px;"/>
            </v-btn> -->
        </span>
        <PriceDisplayPackageCalculator :price="price" v-show="calculator" @highlight="highlight"/>
        <PriceDisplayPackageTiersTable :price="price" v-show="calculator" :highlight-tiers="highlightTiers"/>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { isValidUnsignedDecimalString, fromCurrencyAmountCSU } from '@libertyio/currency-util-js';
// import { toNumber } from '@/sdk/currency';
import PriceDisplayPackageTiersTable from '@/components/PriceDisplayPackageTiersTable.vue';
import PriceDisplayPackageCalculator from '@/components/PriceDisplayPackageCalculator.vue';

export default {
    components: {
        PriceDisplayPackageTiersTable,
        PriceDisplayPackageCalculator,
    },
    props: ['price'],
    data: () => ({
        // tiersTable: false,
        calculator: false,
        highlightTiers: null,
    }),
    computed: {
        ...mapState({
            brandprofile: (state) => state.brandprofile,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
            accentColor: 'accentColor',
        }),
        more() {
            return this.price.tiers.length > 1;
        },
        unitLabel() {
            return this.price.unit_label ?? 'unit';
        },
        flat() {
            return Number.isInteger(this.price.tiers[0].flat_price_csu) && this.price.tiers[0].flat_price_csu > 0;
        },
        unit() {
            return isValidUnsignedDecimalString(this.price.tiers[0].unit_price_cds) && Number.parseFloat(this.price.tiers[0].unit_price_cds) > 0;
        },
        flatAnywhere() {
            return this.price.tiers.filter((item) => Number.isInteger(item.flat_price_csu) && item.flat_price_csu > 0).length > 0;
        },
        unitAnywhere() {
            return this.price.tiers.filter((item) => isValidUnsignedDecimalString(item.unit_price_cds) && Number.parseFloat(item.unit_price_cds) > 0).length > 0;
        },
        both() {
            return this.flat && this.unit;
        },
    },
    methods: {
        formatFlatPrice(tier) {
            // return fromCurrencyAmountCSU(this.price.currency, this.price.tiers[0].flat_price_csu).toStringWithCurrencySymbol();
            const price = fromCurrencyAmountCSU(this.price.currency, tier.flat_price_csu ?? 0).toNumber();
            const display = new Intl.NumberFormat('en-US', {
                currency: this.price.currency,
                style: 'currency',
            }).format(price);
            return display;
        },
        formatUnitPrice(tier) {
            // return fromCurrencyAmountCDS(this.price.currency, tier.unit_price_cds).toStringWithCurrencySymbol();
            const price = Number.parseFloat(tier.unit_price_cds ?? '0');
            const display = new Intl.NumberFormat('en-US', {
                currency: this.price.currency,
                style: 'currency',
            }).format(price);
            return display;
        },
        highlight(event) {
            if (Array.isArray(event?.tierIndexList)) {
                this.highlightTiers = [...event?.tierIndexList];
            }
        },
    },
};
</script>
